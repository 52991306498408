<template>
  <div class="nav-bar">
    <ul class="flex">
      <!-- Logo -->
      <li class="-ml-4">
        <router-link to="/"><img src="../assets/img/logo.png"/></router-link>
      </li>

      <!-- Student -->
      <li v-if="role !== 'STD'" class="ml-4">
        <router-link :to="'/project/student/'">ข้อมูลนักศึกษา</router-link>
      </li>

      <!-- Project -->
      <li>
        <router-link v-if="role === 'STD' && error" :to="'/project/add/'" class="mr-8" >แบบฟอร์มเสนอหัวข้อโครงงาน</router-link>
        <router-link v-if="role === 'STD' && projects.length > 0" :to="project_path">โครงงานของฉัน</router-link>
        <router-link v-else-if="role !== 'STD'" to="/project/">ข้อมูลโครงงาน</router-link>
      </li>

      <!-- Exam -->
      <li>
        <router-link v-if="role === 'STD' && projects.length > 0" :to="'/exam/'">ห้องสอบ</router-link>
        <router-link v-else-if="role !== 'STD'" :to="'/exam/'">ห้องสอบ</router-link>
      </li>

      <!-- Status manage -->
      <li v-if="role === 'STF'">
        <router-link :to="'/status/manage'">ตรวจสอบเอกสาร</router-link>
      </li>

      <!-- Course & Fobi -->
      <li v-if="role === 'STF'">
        <button v-on:click="toggleOtherDropdown()" ref="otherDropdownRef" class="flex flex-row mx-auto">อื่น ๆ <svg class="h-5 w-5 my-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" /></svg></button>
        <div v-bind:class="{hidden: !otherDropdown}" class="dropdown-box" ref="otherDropdown">
          <div class="dropdown-space"></div>
          <div class="border rounded">
            <div v-if="role === 'STF'">
              <router-link :to="'/course/'" class="dropdown-item">ตั้งค่ารายวิชา</router-link><hr>
              <a href="https://carbon.it.kmitl.ac.th/api/token/login" target="_blank" class="dropdown-item">ตั้งค่าแบบฟอร์มเอกสาร</a>
            </div>  
          </div>
        </div>
      </li>

      <!-- Name & Log out -->
      <div class="ml-auto my-auto">
        <li v-if="name" class="font-bold">{{ name }} |</li>
        <li v-if="access">
          <router-link to="/logout" class="-ml-8" style="color:red">ออกจากระบบ</router-link>
        </li>
        <li v-else>
          <router-link to="/login">เข้าสู่ระบบ</router-link>
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core"
export default {
  name: "NavBar",
  data() {
    return {
      name: "",
      role: null,
      projects: [],
      project_path: '/project/list/',
      access: false,
      error: false,
      otherDropdown: false,
    }
  },
  async created() {
    this.name = this.$store.getters.payload.name
    this.role = this.$store.getters.payload.role
    this.access = this.$store.getters.loggedIn
    // Check this user has project or not
    await this.$store.dispatch("checkProjectByUserId", this.$store.getters.payload.user_id).catch(error => this.error = !error)
    this.projects = await this.$store.dispatch("getProjectByUserId", this.$store.getters.payload.user_id)
    this.project_path = this.projects.length == 1 ? "/project/" + this.projects[0].id + "/" : "/project/list/"
  },
  methods: {
    toggleOtherDropdown: function() {
      if (this.otherDropdown) {
        this.otherDropdown = false
      } else {
        this.otherDropdown = true
        createPopper(this.$refs.otherDropdownRef, this.$refs.otherDropdown, { placement: "bottom-start" })
      }
    }
  },
}
</script>
